import { LoadingButton } from "@mui/lab";
import { Alert, Autocomplete, Button, FormHelperText, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AlignLeft, CalendarDate, Check, CheckCircle, CheckDone01, ChevronLeft, Plus, TextInput, Trash01 } from "@untitled-ui/icons-react/build/esm";
import { useFormik } from "formik";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useBoolean } from "usehooks-ts";
import * as Yup from "yup";
import { Dialog } from "~/components/dialog";
import { CreateCustomFieldVariables, useCreateCustomField } from "~/queries/useCreateCustomField";
import { useDeleteCustomField } from "~/queries/useDeleteCustomField";
import { useGetCustomFields } from "~/queries/useGetCustomFields";
import { useUpdateCustomField } from "~/queries/useUpdateCustomField";
import { CUSTOM_FIELD_MODEL, CUSTOM_FIELD_TYPE, CustomField, CustomFieldValueInput } from "~/types/__generated/gql/graphql";
export interface CustomFieldsDialogProps {
  model: CUSTOM_FIELD_MODEL;
  onClose: () => void;
}
const CUSTOM_FIELD_MODEL_LABEL: Record<CUSTOM_FIELD_MODEL, string> = {
  [CUSTOM_FIELD_MODEL.AGENT_ADDRESS]: "Agent address",
  [CUSTOM_FIELD_MODEL.AGENT_CONTACT]: "Agent contact",
  [CUSTOM_FIELD_MODEL.AGENT_INFO]: "Agent info",
  [CUSTOM_FIELD_MODEL.CLIENT_ADDRESS]: "Client address",
  [CUSTOM_FIELD_MODEL.CLIENT_CONTACT]: "Client contact",
  [CUSTOM_FIELD_MODEL.CLIENT_INFO]: "Client info",
  [CUSTOM_FIELD_MODEL.COMPANY]: "Company",
  [CUSTOM_FIELD_MODEL.USER]: "User",
  [CUSTOM_FIELD_MODEL.FILE]: "File"
};
export const CustomFieldsDialog: FC<CustomFieldsDialogProps> = ({
  model,
  onClose
}) => {
  const {
    data: _customFields = []
  } = useGetCustomFields();
  const customFields = useMemo(() => _customFields.filter(field => field.model === model), [_customFields, model]);
  const deleteCustomField = useDeleteCustomField();
  const [updatingId, setUpdatingId] = useState<string | null>(null);
  const {
    value: isCreatingField,
    toggle: toggleCreatingField
  } = useBoolean(false);
  return <Dialog dialogProps={{
    maxWidth: "sm"
  }} title={<>
					{(!!updatingId || isCreatingField) && <IconButton sx={{
      mr: 1
    }} onClick={() => {
      if (updatingId) setUpdatingId(null);
      if (isCreatingField) toggleCreatingField();
    }}>
							<SvgIcon>
								<ChevronLeft />
							</SvgIcon>
						</IconButton>}
					{!!updatingId && <>Update custom field</>}
					{!!isCreatingField && <>Create new field</>}
					{!updatingId && !isCreatingField && <>Manage custom fields for {CUSTOM_FIELD_MODEL_LABEL[model]}</>}
				</>} content={<Stack mt={0.25}>
					{(!!updatingId || isCreatingField) && <CustomFieldForm fieldId={updatingId} model={model} onDone={() => {
      if (updatingId) setUpdatingId(null);
      if (isCreatingField) toggleCreatingField();
    }} />}
					{!updatingId && !isCreatingField && <List>
							{customFields.map(field => <CustomFieldPreview key={field._id} field={field} onDelete={id => deleteCustomField.mutateAsync({
        id
      })} onSelect={setUpdatingId} />)}
						</List>}
				</Stack>} secondaryActionButtonProps={!!updatingId || isCreatingField ? undefined : {
    startIcon: <Plus />,
    children: "Create new field",
    onClick: toggleCreatingField
  }} cancelButtonProps={!!updatingId || isCreatingField ? undefined : {
    onClick: onClose,
    children: "Close"
  }} data-sentry-element="Dialog" data-sentry-component="CustomFieldsDialog" data-sentry-source-file="index.tsx" />;
};
const FIELD_TYPE_LABEL: Record<CUSTOM_FIELD_TYPE, string> = {
  [CUSTOM_FIELD_TYPE.TEXT_SINGLE]: "Text single line",
  [CUSTOM_FIELD_TYPE.TEXT_MULTI]: "Text multi lines",
  [CUSTOM_FIELD_TYPE.SELECT_SINGLE]: "Select single",
  [CUSTOM_FIELD_TYPE.SELECT_MULTI]: "Select multiple",
  [CUSTOM_FIELD_TYPE.DATETIME]: "Datetime"
};
const FIELD_TYPE_ICON: Record<CUSTOM_FIELD_TYPE, ReactNode> = {
  [CUSTOM_FIELD_TYPE.TEXT_SINGLE]: <TextInput />,
  [CUSTOM_FIELD_TYPE.TEXT_MULTI]: <AlignLeft />,
  [CUSTOM_FIELD_TYPE.SELECT_SINGLE]: <CheckCircle />,
  [CUSTOM_FIELD_TYPE.SELECT_MULTI]: <CheckDone01 />,
  [CUSTOM_FIELD_TYPE.DATETIME]: <CalendarDate />
};
const CustomFieldPreview: FC<{
  field: Pick<CustomField, "_id" | "name" | "type">;
  onDelete: (id: string) => void;
  onSelect: (id: string) => void;
}> = ({
  onSelect,
  onDelete,
  field
}) => {
  return <ListItemButton onClick={() => onSelect(field._id)} data-sentry-element="ListItemButton" data-sentry-component="CustomFieldPreview" data-sentry-source-file="index.tsx">
			<ListItemIcon data-sentry-element="ListItemIcon" data-sentry-source-file="index.tsx">{FIELD_TYPE_ICON[field.type]}</ListItemIcon>
			<ListItemText primary={field.name} data-sentry-element="ListItemText" data-sentry-source-file="index.tsx" />
			<IconButton color="error" onClick={e => {
      e.stopPropagation();
      onDelete(field._id);
    }} data-sentry-element="IconButton" data-sentry-source-file="index.tsx">
				<Trash01 data-sentry-element="Trash01" data-sentry-source-file="index.tsx" />
			</IconButton>
		</ListItemButton>;
};
const CustomFieldForm: FC<{
  model: CUSTOM_FIELD_MODEL;
  fieldId?: string | null;
  onDone: () => void;
}> = ({
  model,
  fieldId,
  onDone
}) => {
  const [editingOptionIndex, setEditingOptionIndex] = useState<number | null>(null);
  const createCustomField = useCreateCustomField();
  const updateCustomField = useUpdateCustomField();
  const {
    data: _customFields = []
  } = useGetCustomFields();
  const customFields = useMemo(() => _customFields.filter(field => field.model === model), [_customFields, model]);
  const form = useFormik<CreateCustomFieldVariables["input"]>({
    validateOnMount: true,
    initialValues: {
      model,
      name: "",
      type: CUSTOM_FIELD_TYPE.TEXT_SINGLE,
      selectOptions: []
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      type: Yup.string().oneOf(Object.values(CUSTOM_FIELD_TYPE)).required("Required"),
      selectOptions: Yup.array().of(Yup.object({
        name: Yup.string().required("Required")
      }).required()).when("type", {
        is: type => [CUSTOM_FIELD_TYPE.SELECT_SINGLE, CUSTOM_FIELD_TYPE.SELECT_MULTI].includes(type),
        then: schema => schema.required().min(2, "At least 2 options is required"),
        otherwise: schema => schema.notRequired()
      })
    }),
    onSubmit: async values => {
      if (fieldId) await updateCustomField.mutateAsync({
        id: fieldId,
        input: values
      });else await createCustomField.mutateAsync({
        input: values
      });
      onDone();
    }
  });
  useEffect(() => {
    if (fieldId) {
      const field = customFields.find(field => field._id === fieldId);
      if (field) {
        void form.setValues({
          name: field.name,
          type: field.type,
          model: field.model,
          selectOptions: field.selectOptions?.map(f => ({
            name: f.name
          })) ?? []
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields, fieldId]);
  return <Stack spacing={3} component="form" onSubmit={form.handleSubmit} data-sentry-element="Stack" data-sentry-component="CustomFieldForm" data-sentry-source-file="index.tsx">
			<TextField autoFocus label="Name" {...form.getFieldProps("name")} error={!!form.errors.name} helperText={form.errors.name} data-sentry-element="TextField" data-sentry-source-file="index.tsx" />
			<TextField select label="Type" disabled={!!fieldId} SelectProps={{
      native: true
    }} {...form.getFieldProps("type")} error={!!form.errors.type} helperText={form.errors.type} data-sentry-element="TextField" data-sentry-source-file="index.tsx">
				{Object.values(CUSTOM_FIELD_TYPE).map(type => <option key={type} value={type}>
						{FIELD_TYPE_LABEL[type]}
					</option>)}
			</TextField>
			{[CUSTOM_FIELD_TYPE.SELECT_SINGLE, CUSTOM_FIELD_TYPE.SELECT_MULTI].includes(form.values.type) && <Stack>
					<InputLabel>Select options</InputLabel>
					<List>
						{!!form.values.selectOptions?.length && form.values.selectOptions.map((option, index) => index === editingOptionIndex ? <ListItem key={index} sx={{
          px: 0
        }}>
										<TextField autoFocus fullWidth label="Name" {...form.getFieldProps(`selectOptions[${index}].name`)} />
										<IconButton sx={{
            ml: 1
          }} onClick={() => {
            if (!form.values.selectOptions?.[index]?.name) {
              void form.setFieldValue("selectOptions", form.values.selectOptions?.filter((_, i) => i !== index));
            }
            setEditingOptionIndex(null);
          }}>
											<Check />
										</IconButton>
									</ListItem> : <ListItemButton key={index} onClick={() => setEditingOptionIndex(index)}>
										<ListItemText primary={option.name} />
										<IconButton color="error" onClick={e => {
            e.stopPropagation();
            void form.setFieldValue("selectOptions", form.values.selectOptions?.filter((_, i) => i !== index));
          }}>
											<Trash01 />
										</IconButton>
									</ListItemButton>)}
					</List>
					{!!form.errors.selectOptions && typeof form.errors.selectOptions === "string" && <FormHelperText error>{form.errors.selectOptions}</FormHelperText>}
					<Button fullWidth startIcon={<Plus />} onClick={() => {
        setEditingOptionIndex(form.values.selectOptions?.length ?? 0);
        void form.setFieldValue("selectOptions", [...(form.values.selectOptions ?? []), {
          name: ""
        }]);
      }}>
						Add option
					</Button>
				</Stack>}
			<Box textAlign="right" data-sentry-element="Box" data-sentry-source-file="index.tsx">
				<LoadingButton type="submit" variant="contained" loading={form.isSubmitting} disabled={!form.isValid} data-sentry-element="LoadingButton" data-sentry-source-file="index.tsx">
					{fieldId ? "Update" : "Create"}
				</LoadingButton>
			</Box>
		</Stack>;
};
export const CustomFieldRenderer: FC<{
  field: Pick<CustomField, "_id" | "name" | "selectOptions" | "type">;
  value?: CustomFieldValueInput | null;
  onChange?: (value: CustomFieldValueInput) => void;
  componentProps?: Record<string, any>;
}> = ({
  field,
  value,
  onChange,
  componentProps
}) => {
  switch (field.type) {
    case CUSTOM_FIELD_TYPE.TEXT_SINGLE:
      return <TextField {...componentProps} key={field._id} fullWidth label={field.name} value={value?.textValue ?? ""} onChange={e => onChange?.({
        customField: field._id,
        textValue: e.target.value
      })} />;
    case CUSTOM_FIELD_TYPE.TEXT_MULTI:
      return <TextField {...componentProps} key={field._id} fullWidth multiline minRows={3} label={field.name} value={value?.textValue ?? ""} onChange={e => onChange?.({
        customField: field._id,
        textValue: e.target.value
      })} />;
    case CUSTOM_FIELD_TYPE.SELECT_SINGLE:
      return <Autocomplete {...componentProps} key={field._id} fullWidth options={field.selectOptions ?? []} value={field.selectOptions?.find(o => o._id === value?.selectValues?.[0]) ?? null} isOptionEqualToValue={(option, value) => option._id === value?._id} getOptionLabel={option => option.name} renderInput={params => <TextField {...params} label={field.name} />} onChange={(_, newValue) => onChange?.({
        customField: field._id,
        selectValues: newValue ? [newValue._id] : []
      })} />;
    case CUSTOM_FIELD_TYPE.SELECT_MULTI:
      return <Autocomplete {...componentProps} fullWidth key={field._id} multiple options={field.selectOptions ?? []} value={field.selectOptions?.filter(o => value?.selectValues?.includes(o._id))} isOptionEqualToValue={(option, value) => option._id === value?._id} getOptionLabel={option => option.name} renderInput={params => <TextField {...params} label={field.name} />} onChange={(_, newValue) => onChange?.({
        customField: field._id,
        selectValues: newValue.map(o => o._id)
      })} />;
    case CUSTOM_FIELD_TYPE.DATETIME:
      return <DateTimePicker {...componentProps} sx={{
        width: "100%"
      }} label={field.name} value={value?.dateTimeValue ? new Date(value.dateTimeValue) : null} onAccept={value => onChange?.({
        customField: field._id,
        // @ts-expect-error
        dateTimeValue: value ?? null
      })} />;
    default:
      return <Alert severity="error" title={`Unknown field: ${field.type}`} />;
  }
};