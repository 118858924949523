import { useQuery } from "@tanstack/react-query";

import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import {
	CUSTOM_FIELD_MODEL,
	GetCustomFieldsQuery,
} from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetCustomFields {
		getCustomFields {
			_id
			createdAt
			updatedAt
			name
			type
			model
			company
			selectOptions {
				_id
				createdAt
				updatedAt
				name
			}
		}
	}
`;

export type GetCustomFieldsResults = GetCustomFieldsQuery["getCustomFields"];

export const getCustomFieldsQueryKey = () => ["getCustomFields"];

export const useGetCustomFields = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getCustomFieldsQueryKey(),
		queryFn: async () =>
			apiGqlClient.request(graphql(query)).then((res) => res.getCustomFields),
	});
};

export const useGetCustomFieldsCache = () => {
	return useGetCustomFields(false);
};

export const useGetCompanyCustomFields = () => {
	const { data = [], ...rest } = useGetCustomFields();

	return {
		...rest,
		data: data.filter((field) => field.model === CUSTOM_FIELD_MODEL.COMPANY),
	};
};

export const useGetUserCustomFields = () => {
	const { data = [], ...rest } = useGetCustomFields();

	return {
		...rest,
		data: data.filter((field) => field.model === CUSTOM_FIELD_MODEL.USER),
	};
};

export const useGetAgentInfoCustomFields = () => {
	const { data = [], ...rest } = useGetCustomFields();

	return {
		...rest,
		data: data.filter((field) => field.model === CUSTOM_FIELD_MODEL.AGENT_INFO),
	};
};

export const useGetAgentAddressCustomFields = () => {
	const { data = [], ...rest } = useGetCustomFields();

	return {
		...rest,
		data: data.filter(
			(field) => field.model === CUSTOM_FIELD_MODEL.AGENT_ADDRESS,
		),
	};
};

export const useGetAgentContactCustomFields = () => {
	const { data = [], ...rest } = useGetCustomFields();

	return {
		...rest,
		data: data.filter(
			(field) => field.model === CUSTOM_FIELD_MODEL.AGENT_CONTACT,
		),
	};
};

export const useGetClientInfoCustomFields = () => {
	const { data = [], ...rest } = useGetCustomFields();

	return {
		...rest,
		data: data.filter(
			(field) => field.model === CUSTOM_FIELD_MODEL.CLIENT_INFO,
		),
	};
};

export const useGetClientAddressCustomFields = () => {
	const { data = [], ...rest } = useGetCustomFields();

	return {
		...rest,
		data: data.filter(
			(field) => field.model === CUSTOM_FIELD_MODEL.CLIENT_ADDRESS,
		),
	};
};

export const useGetClientContactCustomFields = () => {
	const { data = [], ...rest } = useGetCustomFields();

	return {
		...rest,
		data: data.filter(
			(field) => field.model === CUSTOM_FIELD_MODEL.CLIENT_CONTACT,
		),
	};
};

export const useGetFileCustomFields = () => {
	const { data = [], ...rest } = useGetCustomFields();

	return {
		...rest,
		data: data.filter((field) => field.model === CUSTOM_FIELD_MODEL.FILE),
	};
};
